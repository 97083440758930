import cartFields from "@api/fragments/cart";

const customPriceProductToCart = /* GraphQL */ `
  mutation customPriceProductInCart($input: CartCustomPriceInput) {
    cart(input: { customPrice: $input }) {
      customPrice {
        cart {
          ...cartFields
        }
      }
    }
  }
  ${cartFields}
`;

export default customPriceProductToCart;
