import { GRAPHQL_ENDPOINT, SITE } from "@api/constants";
import customPriceProductInCartMutation from "@api/mutations/custom-price-product-in-cart";
import Cart from "@api/types/cart";
import CustomPriceProduct from "@api/types/custom-price-product";

type MutationResult = {
  data: {
    cart: {
      customPrice: {
        cart: Cart;
      };
    };
  };
};

/**
 * Customize price for a product in cart.
 */
const customPriceProductInCart = async (token: string, input: CustomPriceProduct): Promise<Cart> => {
  const body = {
    query: customPriceProductInCartMutation,
    variables: {
      input,
    },
  };

  const response = await fetch(GRAPHQL_ENDPOINT, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CORE-SITE": SITE,
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(body),
  });
  const responseBody = (await response.json()) as MutationResult;
  return responseBody?.data.cart.customPrice.cart;
};

export default customPriceProductInCart;
