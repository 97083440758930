import addProductToCart from "@api/operations/add-product-to-cart";
import changeProductInCart from "@api/operations/change-product-in-cart";
import customPriceProductInCart from "@api/operations/custom-price-product-in-cart";
import fetchCustomerState from "@api/operations/fetch-customer-state";
import getToken from "@api/operations/get-token";
import removeProductFromCart from "@api/operations/remove-product-from-cart";
import AddProduct from "@api/types/add-product";
import Cart from "@api/types/cart";
import ChangeProduct from "@api/types/change-product";
import CustomPriceProduct from "@api/types/custom-price-product";
import Customer from "@api/types/customer";
import RemoveProduct from "@api/types/remove-product";
import cookie from "js-cookie";
import { FC, createContext, useState, useEffect, PropsWithChildren } from "react";

type ContextProps = {
  customer?: Customer;
  cart?: Cart;
  addProduct: (input: AddProduct) => Promise<void>;
  removeProduct: (input: RemoveProduct) => void;
  changeProduct: (input: ChangeProduct) => void;
  customPriceProduct: (input: CustomPriceProduct) => void;
  token?: string;
};

const UserContext = createContext<ContextProps>({
  addProduct: () => Promise.resolve(),
  removeProduct: () => {},
  changeProduct: () => {},
  customPriceProduct: () => {},
});

const UserProvider: FC<PropsWithChildren> = ({ children }) => {
  const [customer, setCustomer] = useState<Customer>(null);
  const [cart, setCart] = useState<Cart>(null);
  const [token, setToken] = useState<string>(null);

  /**
   * Establish the token either from the cookie OR if no cookie has been
   * established yet then request a new token from the backend server.
   */
  useEffect(() => {
    const cookieToken = cookie.get("token");

    if (cookieToken) {
      setToken(cookieToken);
    } else {
      getToken().then((serverToken) => {
        cookie.set("token", serverToken);
        setToken(serverToken);
      });
    }
  }, []);

  /**
   * Fetch the user and cart once whenever a token has changed.
   */
  useEffect(() => {
    // don't fetch details until you have a token
    if (!token) {
      return;
    }
    // graphql to query from both a cart and a user
    fetchCustomerState(token).then(({ customer, cart }) => {
      setCart(cart);
      setCustomer(customer);
    });
  }, [token]);

  const addProduct = async (input: AddProduct) => {
    const newCart = await addProductToCart(token, input);
    setCart(newCart);
  };

  const removeProduct = async (input: RemoveProduct) => {
    const newCart = await removeProductFromCart(token, input);
    setCart(newCart);
  };

  const changeProduct = async (input: ChangeProduct) => {
    const newCart = await changeProductInCart(token, input);
    setCart(newCart);
  };

  const customPriceProduct = async (input: CustomPriceProduct) => {
    const newCart = await customPriceProductInCart(token, input);
    setCart(newCart);
  };

  return (
    <>
      <UserContext.Provider
        value={{
          customer,
          cart,
          addProduct,
          removeProduct,
          changeProduct,
          customPriceProduct,
          token,
        }}>
        {children}
      </UserContext.Provider>
    </>
  );
};

const UserConsumer = UserContext.Consumer;

export default UserContext;

export { UserProvider, UserConsumer };
