import { CLIENT_URL } from "@api/constants";
import { appWithTranslation } from "next-i18next";
import { DefaultSeo } from "next-seo";
import type { AppProps } from "next/app";
import { FC, PropsWithChildren } from "react";

import Head from "@components/Head";
import { UserProvider } from "@components/UserContext";

import "../assets/main.css";

const Noop: FC<PropsWithChildren> = ({ children }) => <>{children}</>;

function MyApp({ Component, pageProps }: AppProps) {
  const Layout = (Component as any).Layout || Noop;

  return (
    <>
      <Head />
      <DefaultSeo
        openGraph={{
          type: "website",
          url: CLIENT_URL,
          images: [
            {
              url: CLIENT_URL + "/static/preview.jpg",
              width: 1200,
              height: 630,
              alt: "Mount Olive Family Medicine Center Online Training",
            },
          ],
        }}
        twitter={{
          cardType: "summary",
          handle: "@KenCookCompany",
          site: "@MountOliveCompany",
        }}
        additionalLinkTags={[
          {
            rel: "icon",
            type: "image/x-icon",
            sizes: "64x66",
            href: "/static/favicon.ico",
          },
        ]}
        additionalMetaTags={[
          {
            name: "google-site-verification",
            content: "RijVgdOqMV-oHsjMh2KG1Uhw3SO7ddLNLVSCoQiA5nk",
          },
        ]}
      />
      <UserProvider>
        <Layout pageProps={pageProps}>
          <Component {...pageProps} />
        </Layout>
      </UserProvider>
    </>
  );
}

export default appWithTranslation(MyApp);
