import { GET_TOKEN_ENDPOINT, SITE } from "@api/constants";

type GetTokenResponse = {
  token: string;
};

const getToken = async (): Promise<string> => {
  const response = await fetch(GET_TOKEN_ENDPOINT, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CORE-SITE": SITE,
    },
  });
  const responseBody = (await response.json()) as GetTokenResponse;
  return responseBody?.token;
};

export default getToken;
