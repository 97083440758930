const cartFields = /* GraphQL */ `
  fragment cartFields on CartPayload {
    id
    items {
      id
      price {
        currency
        units
      }
      product {
        id
        mpn
        title
        thumbnail {
          src
        }
        docType: attribute(type: "doc_type")
      }
      quantity
      attributes {
        name
        value
      }
    }
    totals {
      subTotal {
        currency
        units
      }
    }
  }
`;

export default cartFields;
